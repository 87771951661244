import {
  CircularProgress,
  Grid,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { CustomDivider } from "../k2t-stats/CustomDivider";
import { useAxios } from "provider/AxiosProvider";
import React, { useEffect, useState } from "react";
import { RpcInfoDto, ServerInfo, SolanaServiceInfo, Wallet } from "types/info";
import { queryOne } from "utils/rest/restApi";

const lamportsToSol = (lamports: number) => lamports / 1000000000; // 1 SOL === 10^9 Lamports

const truncateGitHash = (hash: string) => hash.slice(0, 7);

export default function InfoPage() {
  const [solanaServiceInfo, setSolanaServiceInfo] =
    useState<SolanaServiceInfo>();
  const axios = useAxios();

  useEffect(() => {
    queryOne<SolanaServiceInfo>(axios, "solana-service-info").then(
      setSolanaServiceInfo
    );
  }, [axios]);

  return (
    <Paper sx={{ p: 2 }}>
      <GitInfos />
      <CustomDivider />
      {solanaServiceInfo ? (
        <SolanaServiceInfos solanaServiceInfo={solanaServiceInfo} />
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
}

function GitInfos() {
  const [serverInfo, setServerInfo] = useState<ServerInfo>();
  const [solanaServiceInfo, setSolanaServiceInfo] =
    useState<SolanaServiceInfo>();

  const axios = useAxios();

  useEffect(() => {
    queryOne<ServerInfo>(axios, "actuator/info").then(setServerInfo);
  }, [axios]);

  useEffect(() => {
    queryOne<SolanaServiceInfo>(axios, "solana-service-info").then(
      setSolanaServiceInfo
    );
  }, [axios]);

  return (
    <>
      <Typography variant="h6">Admin Web Version</Typography>
      <Grid container>
        {process.env.REACT_APP_GIT_TAG && (
          <CustomListItemText
            label="Tag"
            value={process.env.REACT_APP_GIT_TAG}
          />
        )}
        <CustomListItemText
          label="Commit"
          value={truncateGitHash(process.env.REACT_APP_GIT_COMMIT!!)}
        />
        <CustomListItemText
          label="Commit Time"
          value={format(new Date(process.env.REACT_APP_GIT_DATE!!), "Pp")}
        />
      </Grid>
      <CustomDivider />
      <Typography variant="h6">Server Version</Typography>
      {serverInfo ? (
        <Grid container>
          <CustomListItemText label="Tag" value={serverInfo.git.branch} />
          <CustomListItemText
            label="Commit"
            value={truncateGitHash(serverInfo.git.commit.id)}
          />
          <CustomListItemText
            label="Commit Time"
            value={format(new Date(serverInfo.git.commit.time), "Pp")}
          />
        </Grid>
      ) : (
        <CircularProgress />
      )}
      <CustomDivider />
      <Typography variant="h6">Solana Service Version</Typography>
      {solanaServiceInfo ? (
        <Grid container>
          <CustomListItemText label="Tag" value={solanaServiceInfo.git.tag} />
          <CustomListItemText
            label="Commit"
            value={truncateGitHash(solanaServiceInfo.git.commit)}
          />
          <CustomListItemText
            label="Commit Time"
            value={format(new Date(solanaServiceInfo.git.date), "Pp")}
          />
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

function SolanaServiceInfos(props: { solanaServiceInfo: SolanaServiceInfo }) {
  const solanaServiceInfo = props.solanaServiceInfo;
  return (
    <>
      <Typography variant="h6">Membership Wallet</Typography>
      <WalletInfos wallet={props.solanaServiceInfo.wallets.membership} />
      <CustomDivider />
      <Typography variant="h6">Token Wallet</Typography>
      <WalletInfos wallet={props.solanaServiceInfo.wallets.token} />
      <CustomDivider />
      <RpcInfos rpcs={solanaServiceInfo.rpcs} />
      <CustomDivider />
      <Typography variant="h6">Bundlr</Typography>
      <Grid container>
        <CustomListItemText label="Url" value={solanaServiceInfo.bundlr.url} />
        <CustomListItemText
          label="Balance"
          value={
            lamportsToSol(solanaServiceInfo.bundlr.balance).toLocaleString() +
            " SOL"
          }
        />
      </Grid>
    </>
  );
}

function RpcInfos(props: { rpcs: RpcInfoDto[] }) {
  return (
    <>
      {props.rpcs.map((rpc, index) => (
        <span key={index}>
          <Typography variant="h6">
            {index === 0 ? "Default RPC" : `Fallback RPC #${index}`}
          </Typography>
          <RpcInfo rpc={rpc} />
          <CustomDivider />
        </span>
      ))}
    </>
  );
}

function RpcInfo(props: { rpc: RpcInfoDto }) {
  return (
    <>
      <CustomListItemText label="URL" value={props.rpc?.url} />
      <CustomListItemText label="Version" value={props.rpc?.version} />
      <CustomListItemText
        label="Health"
        value={props.rpc?.health && JSON.stringify(props.rpc?.health, null, 2)}
      />
    </>
  );
}

function WalletInfos(props: { wallet: Wallet }) {
  return (
    <Grid container>
      <CustomListItemText label="Public Address" value={props.wallet.key} />
      <CustomListItemText
        label="Balance"
        value={lamportsToSol(props.wallet.balance).toLocaleString() + " SOL"}
      />
    </Grid>
  );
}

function CustomListItemText(props: { value: string; label: string }) {
  return (
    <Grid item xl={4} lg={6} xs={12}>
      <ListItemText primary={props.value} secondary={props.label} />
    </Grid>
  );
}
